$mobileNavBar-icon-color: $token-color-grey-90;
$mobileNavBar-logo-width: $token-spacing-193px;
$mobileNavBar-loginLink-color: $token-text-color-inverse-primary;
$mobileNavBar-loginLink-active-color: $token-text-color-interaction-active !important;
$mobileNavBar-loginLink-hover-background: $button-primary-hover-background-color !important;
$mobileNavBar-loginLink-background: $button-primary-background-color !important;
$mobileNavBar-loginLink-active-background: $token-background-inverse-primary !important;
$mobileNavBar-loginLink-border-color: $token-background-transparent;
$mobileNavBar-loginLink-border-width: $token-spacing-0;
$mobileNavBar-loginLink-border-radius: $token-spacing-1;
$mobileNavBar-loginLink-padding: $token-spacing-2 $token-spacing-3 !important;
$pageHeader-padding-left: $token-spacing-32px;
$mobileNavBar-level1-nested-padding: $token-spacing-16px $token-spacing-16px $token-spacing-16px $token-spacing-32px;
$mobileNavBar-hamburgerIcon-padding: 0 0 0 $token-spacing-8;
$mobileNavBar-pageHeader-input-marging-left: $token-spacing-8;
$mobileNavBar-pageHeader-label-span-marging-left: $token-spacing-11;
$mobileNavBar-pageHeader-logoutBtn-padding: $token-spacing-4 $token-spacing-5 $token-spacing-8 $token-spacing-8;
$mobileNavBar-regionPickerMobileWrapper-margin: $token-spacing-4 $token-spacing-3 $token-spacing-4 $token-spacing-8;
$mobileNavBar-level-1-li-last-child-border-top: $token-border-width-default solid $token-border-base;
$mobileNavBar-level-1-li-last-child-border-bottom: $token-border-width-default solid $token-border-base;