$desktopNavBar-link-color: $token-color-grey-90;
$desktopNavBar-profileDetails-margin: auto $token-spacing-0 auto $token-spacing-16px !important;
$desktopNavBar-profileDetails-padding: $token-spacing-0 !important;
$desktopNavBar-profile-color: $token-color-grey-90;
$desktopNavBar-profile-icon-after-border: solid $token-color-grey-90;
$desktopNavBar-parentMenu-spacing: $token-spacing-0 $token-spacing-8 $token-spacing-0 $token-spacing-0;
$desktopNavBar-parentMenu-spacing-mobile: $token-spacing-0 $token-spacing-7px;
$desktopNavBar-parentMenu-loginButton-font-size: $token-font-size-root;
$desktopNavBar-parentMenu-a-margin-right: $token-spacing-5px;
$desktopNavBar-parentMenuLink-padding: $token-spacing-3;
$desktopNavBar-parentMenuLink-icon-after-border: solid $token-color-grey-80;
$desktopNavBar-parentMenuLink-border-width: $token-border-width-0 $token-border-width-default $token-border-width-default $token-border-width-0;
$desktopNavBar-logo-padding-top: $token-spacing-2;
$desktopNavBar-logo-width: $token-spacing-193px;
$desktopNavBar-navMenuItems-justify-content: flex-end;
$desktopNavBar-navMenuItems-justify-content-mobile: flex-end;
$desktopNavBar-fullWidth-width: 100% !important;
$desktopNavBar-loginModal-border: $token-border-width-default solid $token-color-grey-30;
$desktopNavBar-profileDropdown-border: $token-border-width-default solid $token-color-grey-30;
$desktopNavBar-profileDropdown-top: $token-spacing-18;
$desktopNavBar-profileDropdown-right: -($token-spacing-4);
$desktopNavBar-whiteBGColor-padding: $token-spacing-0;
$desktopNavBar-subMenuLink-padding: $token-spacing-3 !important;
$desktopNavBar-show-top: $token-spacing-50px;
$desktopNavBar-menuWrapper-align-items: unset;
$desktopNavBar-selected-background-color: $token-color-base-blue !important;
$desktopNavBar-grandSubMenuLink-color: $token-color-base-blue;
$desktopNavBar-dropDownSubMenuForElavon-color-hover: $token-color-base-blue;
$desktopNavBar-logOffLink-padding: $token-spacing-0;
$desktopNavBar-logOffLink-margin: $token-spacing-0;
$desktopNavBar-profileDetailsFullWidth-profileDropdown-right: $token-spacing-0;
$desktopNavBar-profile-width: $token-spacing-368px;
$desktopNavBar-profile-padding: $token-spacing-44px $token-spacing-24px $token-spacing-16px $token-spacing-24px;